import React from 'react';
import { COLORS } from '../../styles/theme-constants';
import { Text } from '../atoms/text';
import { TimelineProps } from './timeline-props';
import {
  TimelineConnector,
  TimelineContent,
  TimelineContentWrapper,
  TimelineItemWrapper,
  TimelineList,
  TimelineListWrapper,
  TimelineSeparator,
  TimelineSeparatorLine,
  Divider,
  EndCircle,
} from './timeline-styles';

export const Timeline = ({
  children,
  dateColor,
  textColor,
  endIcon,
  lineColor,
  circleEnds,
  ...rest
}: TimelineProps): React.ReactElement => {
  return (
    <TimelineListWrapper {...{ textColor, dateColor, endIcon, lineColor }} {...rest}>
      <TimelineList {...{ textColor, dateColor, endIcon, lineColor, circleEnds }}>
        {circleEnds && <EndCircle />}
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) return null;
          return React.cloneElement(child, {
            ...child.props,
            textColor,
            dateColor,
            lineColor,
          });
        })}
        {circleEnds && !endIcon && <EndCircle bottom />}
      </TimelineList>
      {endIcon && endIcon}
    </TimelineListWrapper>
  );
};

export interface TimelineItemProps {
  children: React.ReactNode;
  title?: string;
  date?: string;
  circleDivider?: boolean;
  textColor?: string;
  dateColor?: string;
  lineColor?: string;
  minWidth?: string;
}

export const TimelineItem = (props: TimelineItemProps): React.ReactElement => {
  const {
    children,
    title,
    date,
    circleDivider = false,
    textColor = COLORS.orange100,
    dateColor = COLORS.orange400,
    lineColor = COLORS.blue100,
    minWidth = `60%`,
    ...rest
  } = props;
  return (
    <TimelineItemWrapper {...{ textColor, dateColor, lineColor }} {...rest}>
      <TimelineSeparator {...{ textColor, dateColor, lineColor }}>
        <Divider circleDivider={!date && circleDivider}>{date}</Divider>
        <TimelineSeparatorLine {...{ textColor, dateColor, lineColor }} />
      </TimelineSeparator>
      <TimelineContentWrapper>
        <TimelineConnector {...{ textColor, dateColor, lineColor }}>
          <hr />
        </TimelineConnector>
        <TimelineContent {...{ textColor, dateColor, minWidth }}>
          {title && (
            <Text as="h4" size="h3" mt="none" mb="one" color={textColor}>
              {title}
            </Text>
          )}

          {children}
        </TimelineContent>
      </TimelineContentWrapper>
    </TimelineItemWrapper>
  );
};
