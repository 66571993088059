import React, { useRef, useEffect } from 'react';

import { media } from '@cheese-fondue/helpers';
import { COLORS } from '@cheese-fondue/styles';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import styled from 'styled-components';

import { useInView } from 'react-intersection-observer';
import * as simplexNoise from '../heroWithCircle/dependencies/fluid';

import { run } from 'play-core/index';

const OrangeAsciiWall = (): React.ReactElement => {
  const preRef = useRef<HTMLPreElement>(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (preRef && preRef.current) {
      const settings = {
        scale: 100,
        fps: 15,
        element: preRef.current,
        pause: !inView,
        backgroundColor: COLORS.orange300,
      };

      run(simplexNoise, settings).catch(() => {
        return;
      });
    }
  }, [preRef, inView]);

  return (
    <section ref={ref} style={{ overflow: 'hidden', position: 'relative' }}>
      <Wrapper>{inView && <FixedPre ref={preRef} />}</Wrapper>
    </section>
  );
};

export default OrangeAsciiWall;

const Wrapper = styled.section`
  ${speckledBackground(COLORS.orange300)}
  padding: 40px 0;
  min-height: 500px;

  ${media.medium`
    padding: 96px 0;
`}
`;

const FixedPre = styled.pre`
  color: #1b1730;
  width: 120%;
  height: 120%;
  font-size: 11px;
  position: absolute;
  bottom: -5%;
  left: 0;
  top: 0;
  right: 0;
  margin: 0;
`;
