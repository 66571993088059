import { media } from '@cheese-fondue/helpers';
import { COLORS, SPACING } from '@cheese-fondue/styles';
import { typography, TypographyVariants } from '@cheese-fondue/styles/typography';
import { FluidObject } from 'gatsby-image';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { speckledBackground } from '../../styles/backgrounds';
import { Image } from '../atoms';
import { Text } from '../atoms/text';
import { Box, Grid } from '../grid';
import { AspectRatio } from '../grid/box';
import { LinkWrapper } from '../atoms';

type Shapes = 'Circle' | 'Diamond' | 'Triangle' | 'Diagonal';
type TextAligment = 'center' | 'left';

export interface SimpleMessageProps {
  shape?: Shapes;
  backgroundColor?: string;
  foregroundColor?: string;
  backgroundImage?: FluidObject;
  headline?: string | ReactNode;
  headlineSize?: TypographyVariants | TypographyVariants[];
  textAlignment?: TextAligment;
  body?: string;
  bodySize?: TypographyVariants | TypographyVariants[];
  textMargin?: string;
  textColor?: string;
  children?: ReactNode;
  letterSpacing?: string;
  justifyContent?: string;
  textMarginBottom?: string;
  link?: {
    linkText: string;
    linkPath: string;
    arrowIcon?: ReactElement;
  };
  aspectRatio?: AspectRatio[];
}

const Wrapper = styled.div<Partial<SimpleMessageProps>>`
  width: 100%;
  height: 100%;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : COLORS.blue400)};
  color: ${props => (props.textColor ? props.textColor : COLORS.orange100)};
  padding: 16px;
  ${media.medium`
  padding:24px;
`};
`;

const Content = styled.div<Partial<SimpleMessageProps>>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.textAlignment};
  justify-content: ${props =>
    props.textAlignment === 'center' ? 'center' : props.justifyContent ? props.justifyContent : 'space-between'};
  width: 100%;
  height: 100%;
  ${({ backgroundColor }) => speckledBackground(backgroundColor ?? COLORS.blue400, true)};
`;

const Headline = styled.div<Partial<SimpleMessageProps>>`
  text-align: ${props => props.textAlignment};
  margin: ${props =>
    props.textAlignment === 'center'
      ? `0 ${props.textMargin} ${SPACING.one}px ${props.textMargin}`
      : `${SPACING.one}px 50% ${SPACING.one}px ${SPACING.one}px`};

  ${media.medium`
    margin: ${props =>
      props.textAlignment === 'center'
        ? `0 ${props.textMargin} ${SPACING.one}px ${props.textMargin}`
        : `${SPACING.two}px 50% ${SPACING.two}px ${SPACING.two}px`};

  `}
`;

const Body = styled.p<Partial<SimpleMessageProps>>`
  text-align: ${props => props.textAlignment};
  white-space: pre-wrap;
  margin: 0;

  ${media.medium`
    ${typography.pMedium}
  `};
`;

const Shape = ({ shape, foregroundColor }: { shape?: Shapes; foregroundColor?: string }): ReactElement => {
  return (
    <svg height="100%" width="100%" viewBox="0 0 100 100">
      {shape === 'Circle' && <circle cx="50" cy="50" r="50" fill={foregroundColor} />}
      {shape === 'Triangle' && <polygon points="0,0 100,50 0,100" fill={foregroundColor} />}
      {shape === 'Diamond' && <polygon points="50,100 100,50 50,0 0,50, 50,100" fill={foregroundColor} />}
      {shape === 'Diagonal' && <polygon points="0,100 100,0 100,100" fill={foregroundColor} />}
    </svg>
  );
};

const StyledShape = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundImg = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledLink = styled(LinkWrapper)<Partial<SimpleMessageProps>>`
  font-size: ${SPACING.base}px;
  color: ${props => props.textColor};
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  svg {
    display: inline-block;
    vertical-align: sub;
  }
`;

export const SimpleMessage = ({
  shape,
  backgroundColor,
  backgroundImage,
  foregroundColor,
  textColor,
  headline,
  headlineSize = ['h3', 'h2'],
  textAlignment = 'center',
  body,
  bodySize = 'pMedium',
  children,
  textMargin = '25%',
  letterSpacing,
  justifyContent,
  textMarginBottom,
  link,
  aspectRatio = [AspectRatio.OneByOne],
}: SimpleMessageProps): ReactElement => {
  return (
    <Grid>
      <Box cols={12} aspectRatio={aspectRatio}>
        <Wrapper textColor={textColor} backgroundColor={backgroundColor}>
          {backgroundImage && <BackgroundImg imageData={backgroundImage} />}
          <Content backgroundColor={backgroundColor} textAlignment={textAlignment} justifyContent={justifyContent}>
            <Headline textAlignment={textAlignment} textMargin={textMargin}>
              <Text as="h3" size={headlineSize} letterSpacing={letterSpacing} mb={textMarginBottom}>
                {headline}
              </Text>
            </Headline>
            {body && (
              <Body textAlignment={textAlignment} textMargin={textMargin}>
                <Text as="p" size={bodySize} letterSpacing={letterSpacing} mb={textMarginBottom}>
                  {body}
                </Text>
                {link?.linkText && link?.linkPath && (
                  <StyledLink textColor={textColor} title={`${link.linkText}`} to={link.linkPath}>
                    {link.linkText} {link.arrowIcon}
                  </StyledLink>
                )}
              </Body>
            )}
          </Content>
          {children && children}
          {shape && (
            <StyledShape>
              <Shape shape={shape} foregroundColor={foregroundColor} />
            </StyledShape>
          )}
        </Wrapper>
      </Box>
    </Grid>
  );
};
