import React, { useRef, useEffect, useState } from 'react';
import { Grid, Box } from '@cheese-fondue/components/grid';

import { media, useImageQuery } from '@cheese-fondue/helpers';
import { COLORS, SPACING } from '@cheese-fondue/styles';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import styled from 'styled-components';
import { Text } from '@cheese-fondue/components/atoms';
import { Container } from '@cheese-fondue/styles/container';
import { Stream } from '@cloudflare/stream-react';
import { MessageWheel } from '../messageWheel';
import { useInView } from 'react-intersection-observer';
import { sort } from '../../../../local-dependencies/play-core/src/modules/sort';
import * as pulse from '../businessModel/dependencies/pulse';
import { run } from 'play-core/index';

import desktopMask from '../../../../assets/images/edge-drip-mask.png';
import mobileMask from '../../../../assets/images/edge-drip-mask-mobile.png';

const GetEdge = (): React.ReactElement => {
  const hedgeFundStreamFrame = useImageQuery({ src: 'images/placeholders/hedge-fund-stream-frame.png' });
  const [showVideo, setShowVideo] = useState(false);

  const onPlayHandler = (_: Event): void => {
    if (!showVideo) {
      setShowVideo(true);
    }
  };

  const videoIdOrSignedUrl = '780f05f88749d96980eb52581b0b595a';

  const preRef = useRef<HTMLPreElement>(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (preRef && preRef.current) {
      const settings = {
        scale: 100,
        fps: 60,
        element: preRef.current,
        pause: !inView,
        density: sort('/\\MXYZabc!?=-. ', 'Simple Console', false),
      };

      run(pulse, settings).catch(() => {
        // TODO: replace with fallback image
      });
    }
  }, [preRef, inView]);

  return (
    <section style={{ overflow: 'hidden', position: 'relative' }}>
      <Wrapper>
        <Container>
          <Grid>
            <Box cols={[12, 5]}>
              <Text as="h2" color={COLORS.blue400} size={['h2Variant', 'h1XLarge']}>
                Hedge funds get their edge by buying your:
              </Text>
              <WheelWrapper>
                <MessageWheel
                  messages={[
                    'credit card data',
                    'location data',
                    'Robinhood trades',
                    'browser history',
                    'financial history',
                    'credit card data',
                    'location data',
                    'Robinhood trades',
                    'browser history',
                    'financial history',
                  ]}
                />
              </WheelWrapper>
            </Box>
            <Box cols={[12, 7]}>
              <VideoContainer showVideo={showVideo}>
                <Stream
                  controls={false}
                  preload="auto"
                  autoplay
                  loop
                  muted
                  src={videoIdOrSignedUrl}
                  onPlaying={onPlayHandler}
                />
                {!showVideo && (
                  <Placeholder
                    src={hedgeFundStreamFrame?.src}
                    alt="Video showing data and companies being analyzed to increase returns."
                  />
                )}
              </VideoContainer>
            </Box>
          </Grid>
        </Container>
      </Wrapper>
      <FooterAnim ref={ref}>
        <IllustrationContainer mobileMask={mobileMask} desktopMask={desktopMask}>
          {inView && <FixedPre ref={preRef} />}
        </IllustrationContainer>
      </FooterAnim>
    </section>
  );
};

export default GetEdge;

const Wrapper = styled.section`
  ${speckledBackground(COLORS.orange100)}
  padding: 40px 0;

  ${media.medium`
    padding: 96px 0;
`}
`;

const VideoContainer = styled.div<{ showVideo: boolean }>`
  margin-top: ${SPACING.four}px;

  ${media.medium`
      margin-top: ${SPACING.six}px;
      padding-left: 48px;
  `}

  > div {
    opacity: ${({ showVideo }) => (showVideo ? 1 : 0)};
  }
`;

const Placeholder = styled.img`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const WheelWrapper = styled.div`
  padding: 0;
  margin-top: 40px;

  ${media.medium`
    padding-right: 40px;
  `}
`;

const FooterAnim = styled.div`
  ${speckledBackground(COLORS.orange100)}
  width: 100%;
`;

const IllustrationContainer = styled.div<{ mobileMask: string; desktopMask: string }>`
  width: 100%;
  mask-image: url(${({ mobileMask }) => mobileMask});
  mask-size: cover;
  height: 100%;
  mask-repeat: no-repeat;
  padding-bottom: 100%;
  mask-position: right;

  ${media.medium`
    padding-bottom: 35%;
    mask-size: cover;
    mask-image: url(${({ desktopMask }) => desktopMask});
  `}
`;

const FixedPre = styled.pre`
  color: #1b1730;
  width: 120%;
  height: 50%;
  background: #1b1730;
  font-size: 11px;
  position: absolute;
  margin: 0;
  bottom: -5%;
  left: -10%;
  right: 0;

  ${media.medium`
    height:40%;
  `}
`;
