import React from 'react';
import { Wrapper, LogoTextLinkBlockWrapper } from './logoTextLinkBlockStyles';
import { LogoTextLink, LogoTextLinkProps } from '@cheese-fondue/components/atoms';

export interface LogoTextLinkBlockProps {
  data: LogoTextLinkProps[];
}

export const LogoTextLinkBlock = ({ data }: LogoTextLinkBlockProps): React.ReactElement => {
  return (
    <LogoTextLinkBlockWrapper>
      <Wrapper>
        {data.map(logoTextLink => {
          return (
            <LogoTextLink
              key={logoTextLink.text}
              imageData={logoTextLink.imageData}
              imageAltText={logoTextLink.imageAltText}
              text={logoTextLink.text}
              link={logoTextLink.link}
            />
          );
        })}
      </Wrapper>
    </LogoTextLinkBlockWrapper>
  );
};
