import { COLORS } from '@cheese-fondue/styles';
import React, { ReactElement } from 'react';
import { AspectRatio, Box, Grid } from '../grid';
import { SimpleMessage } from '../simpleMessage';
import { useImageQuery } from '@cheese-fondue/helpers';

import { ArrowRightWhite } from '@cheese-fondue/components/atoms';
import { ArrowRightSmall } from '@cheese-fondue/components/atoms';
export const PressGallery = (): ReactElement => {
  const noteBG = useImageQuery({ src: 'images/dollarnote-bg.jpg' });
  const greenMarble = useImageQuery({ src: 'images/how-it-works-algo-background.jpg' });
  const orangeMarble = useImageQuery({ src: 'cheese-fondue/orange-marble-1600-1600.jpg' });
  if (!noteBG || !greenMarble || !orangeMarble) throw new Error('missing press image');

  return (
    <Grid>
      <Box cols={[12, 6]}>
        <Grid>
          <Box cols={[12]}>
            <SimpleMessage
              backgroundColor={COLORS.orange200}
              headline="News Coverage"
              headlineSize={['h1', 'h1XLarge', 'h1XXLarge']}
              textColor={COLORS.red}
              textAlignment="left"
              justifyContent="flex-start"
              aspectRatio={[AspectRatio.TwoByOne]}
            />
          </Box>
          <Box cols={[0, 6]}>
            <SimpleMessage
              backgroundColor={COLORS.orange200}
              aspectRatio={[AspectRatio.TwoByOne, AspectRatio.OneByOne]}
            />
          </Box>

          <Box cols={[12, 6]}>
            <SimpleMessage
              aspectRatio={[AspectRatio.TwoByOne, AspectRatio.OneByOne]}
              backgroundColor={COLORS.orange100}
              bodySize={['h3', 'h3Fit', 'h3Fit']}
              body="Algorithmic Stock Advisor Delphia Raises $60M Ahead of Rewards Token Launch
                Machine Learning Hedge Fund Hires AWS's Lead Scientist and Point72 Veteran Andrew Arnold"
              textColor={COLORS.blue400}
              textAlignment="left"
              justifyContent="flex-end"
              textMarginBottom="one"
              link={{
                linkText: 'Read More',
                linkPath:
                  'https://www.institutionalinvestor.com/article/2bstnox9764n3ivg6al8g/corner-office/machine-learning-hedge-fund-hires-awss-lead-scientist-and-point72-veteran-andrew-arnold',
                arrowIcon: <ArrowRightSmall />,
              }}
            />
          </Box>
          <Box cols={[12, 0]}>
            <SimpleMessage
              backgroundColor={COLORS.teal100}
              backgroundImage={greenMarble}
              bodySize={['h2', 'h2', 'h1']}
              textAlignment="left"
              textColor={COLORS.white}
              justifyContent="flex-end"
              body={
                'Delphia Raises $60 Million, Aims to Use Social Data to Power Algo Investing, Allowing Anyone to Invest Better'
              }
              textMarginBottom="one"
              link={{
                linkText: 'Read More',
                linkPath:
                  'https://techcrunch.com/2022/06/08/multicoin-capital-leads-60m-investment-in-delphia-to-give-retail-investors-edge-against-hedge-funds/',
                arrowIcon: <ArrowRightWhite />,
              }}
            />
          </Box>
          <Box cols={[6, 6]}>
            <SimpleMessage
              backgroundColor={COLORS.orange100}
              bodySize={['h4', 'h3Fit']}
              body="How Delphia Hopes To Use Personal Data And Algorithms To Help Investors Beat The Market"
              textColor={COLORS.blue400}
              textAlignment="left"
              justifyContent="flex-end"
              textMarginBottom="one"
              link={{
                linkText: 'Read More',
                linkPath:
                  'https://www.benzinga.com/fintech/22/01/24823686/exclusive-how-delphia-hopes-to-use-personal-data-and-algorithms-to-help-investors-beat-the-market',
                arrowIcon: <ArrowRightSmall />,
              }}
            />
          </Box>
          <Box cols={[6, 6]}>
            <SimpleMessage backgroundColor={COLORS.orange200} backgroundImage={orangeMarble} />
          </Box>
        </Grid>
      </Box>
      <Box cols={[12, 6]}>
        <Grid>
          <Box cols={[0, 12]}>
            <SimpleMessage
              backgroundColor={COLORS.teal100}
              backgroundImage={greenMarble}
              bodySize={['h4', 'h3', 'h1']}
              textAlignment="left"
              textColor={COLORS.white}
              justifyContent="flex-end"
              body={
                'Delphia Raises $60 Million, Aims to Use Social Data to Power Algo Investing, Allowing Anyone to Invest Better'
              }
              textMarginBottom="one"
              link={{
                linkText: 'Read More',
                linkPath:
                  'https://techcrunch.com/2022/06/08/multicoin-capital-leads-60m-investment-in-delphia-to-give-retail-investors-edge-against-hedge-funds/',
                arrowIcon: <ArrowRightWhite />,
              }}
            />
          </Box>

          <Box cols={[6]}>
            <SimpleMessage
              backgroundColor={COLORS.orange100}
              bodySize={['h4', 'h3Fit']}
              textAlignment="left"
              textColor={COLORS.blue400}
              justifyContent="flex-end"
              body={'With This Platform, Regular Folks Reap Benefits From Their Data—And So Do Individual Investors'}
              textMarginBottom="one"
              link={{
                linkText: 'Read more',
                linkPath:
                  'https://www.forbes.com/sites/annefield/2019/12/11/with-this-platform-regular-folks-reap-benefits-from-their-data-and-so-do-individual-investors/',
                arrowIcon: <ArrowRightSmall />,
              }}
            />
          </Box>
          <Box cols={[6]}>
            <SimpleMessage
              backgroundColor={COLORS.black}
              backgroundImage={noteBG}
              bodySize={['h4', 'h3Fit']}
              textAlignment="left"
              textColor={COLORS.white}
              justifyContent="flex-end"
              body={'Analyzing Data to Make Investment Decisions (Nasdaq Interview)'}
              textMarginBottom="one"
              link={{
                linkText: 'Watch Now',
                linkPath: 'https://www.youtube.com/watch?v=_o9b423CEqM',
                arrowIcon: <ArrowRightWhite />,
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
