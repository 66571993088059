import { COLORS, FONTS } from '@cheese-fondue/styles';
import React, { ReactElement } from 'react';
import { ArrowWrapper, Wrapper } from './linkAndSmallTextStyles';
import { ArrowRight } from '@cheese-fondue/components/atoms';
import { Text } from '@cheese-fondue/components/atoms';

export interface LinkAndSmallTextProps {
  linkText: string;
  linkUrl: string;
  text: string;
  hasArrow?: boolean;
}

export const LinkAndSmallText = (props: LinkAndSmallTextProps): ReactElement => {
  const { linkText, linkUrl, hasArrow, text } = props;

  return (
    <Wrapper>
      <Text
        as="a"
        href={linkUrl}
        color={COLORS.blue300}
        fontFamily={FONTS.headings}
        size={['pMedium']}
        textDecoration={'none'}
      >
        {linkText}{' '}
        {hasArrow && (
          <ArrowWrapper>
            <ArrowRight />
          </ArrowWrapper>
        )}
      </Text>
      <Text as="p" size={'p'} mt={'one'} color={COLORS.blue300}>
        {text}
      </Text>
    </Wrapper>
  );
};
