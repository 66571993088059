import { FONTS } from '@cheese-fondue/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 87%;

  a {
    font-family: ${FONTS.headings};
  }
`;

export const ArrowWrapper = styled.span`
  svg {
    display: inline-block;
    vertical-align: bottom;
  }
`;
