import { useEffect, useState } from 'react';

export interface UseAccordion {
  defaultIndex?: number;
}

interface UseAccordionReturn {
  index: number;
  getAccordionItemProps: (itemIndex: number | null) => GetAccordionItemPropsReturn;
}

interface GetAccordionItemPropsReturn {
  isOpen: boolean;
  onChange: (isOpen: boolean) => void;
}

export const useAccordion = ({ defaultIndex = -1 }: UseAccordion): UseAccordionReturn => {
  const [index, setIndex] = useState(defaultIndex);

  // reset the view after unmount
  useEffect(() => {
    return () => setIndex(-1);
  }, []);

  /**
   * Gets the `isOpen` prop for a child accordion item based on
   * the child's index.
   *
   * @param itemIndex {number} The index of the child accordion item
   */
  const getAccordionItemProps = (itemIndex: number | null): GetAccordionItemPropsReturn => {
    const isOpen = itemIndex !== null ? index === itemIndex : false;

    const onChange = (isOpen: boolean): void => {
      if (itemIndex === null) return;
      if (!isOpen) {
        setIndex(itemIndex);
      } else {
        setIndex(-1);
      }
    };

    return { isOpen, onChange };
  };

  return { getAccordionItemProps, index };
};

export const createAccordionItemId = (id: string, index: number): { buttonId: string; panelId: string } => {
  // get rid of any spaces to be HTML compliant
  const _id = id.split(' ').join('');
  return {
    buttonId: `accordion-button-${_id}-${index}`,
    panelId: `accordion-panel-${_id}-${index}`,
  };
};
