import { motion } from 'framer-motion';
import styled from 'styled-components';
import { COLORS, SPACING } from '../../styles';
import { ANIMATION_CURVES } from '../../styles/theme-constants';
import { typography } from '../../styles/typography';
import { AccordionItemProps } from './accordion-props';
import { TypographyVariants } from '@cheese-fondue/styles/typography';
import { media } from '@cheese-fondue/helpers';
import { speckledBackground } from '../../styles/backgrounds';

const getFontSize = (props: AccordionItemProps): string | TypographyVariants[] => {
  const fontSize = props.textSize ? props.textSize : typography.h2;
  return fontSize;
};

export const AccordionWrapper = styled.div<Partial<AccordionItemProps>>`
  width: 100%;
  ${({ backgroundColor = COLORS.purple400, plain = false }) =>
    plain ? `background-color: ${backgroundColor}` : speckledBackground(backgroundColor)};
  border-top: 2px solid ${({ lineColor = COLORS.orange100 }) => lineColor};
  overflow-anchor: none;
  padding: 10px;

  h3 {
    padding: 0;
    margin: 0;
    border: 0;
  }
`;

export const AccordionButton = styled.button<{
  color?: string;
  textAlignment?: string;
  textSize?: TypographyVariants | TypographyVariants[];
}>`
  width: 100%;
  padding: ${SPACING.one}px ${SPACING.three}px ${SPACING.one}px
    ${({ textAlignment = 'center' }) => (textAlignment === 'center' ? `${SPACING.three}px` : '0')};
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  color: ${({ color = COLORS.orange100 }) => color};
  position: relative;
  text-align: ${({ textAlignment = 'center' }) => textAlignment};

  &:focus-visible {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
    outline: none;
  }

  ${props => getFontSize}

  ${typography.h3};

  ${media.medium`
    ${typography.h2};
  `}
`;

export const AccordionContent = styled(motion.div)<{
  color?: string;
  textAlignment?: string;
}>`
  overflow: hidden;
  width: 100%;
  height: 0;
  opacity: 0;
  color: ${({ color = COLORS.orange100 }) => color};
  text-align: ${({ textAlignment = 'center' }) => textAlignment};

  a {
    color: ${({ color = COLORS.orange100 }) => color};
    text-decoration: underline;
  }
`;

export const CloseButton = styled.div<{ isOpen?: boolean; color?: string }>`
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;

  span {
    height: 20px;
    width: 20px;

    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      height: 2.5px;
      width: 20px;
      background-color: ${({ color = COLORS.orange100 }) => color};
      transition: all 0.25s;
      transition-timing-function: cubic-bezier(${ANIMATION_CURVES.bezier});
    }

    &:after {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(-90deg)')};
    }
  }
`;
