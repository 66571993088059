import { COLORS } from '@cheese-fondue/styles';
import { ANIMATION_CURVES, SPACING } from '@cheese-fondue/styles/theme-constants';
import { typography } from '@cheese-fondue/styles/typography';
import { AnimatePresence, motion, transform } from 'framer-motion';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

export interface MessageWheelProps {
  messages: string[];
  animationInterval?: number;
  animationTime?: number;
}

const verticalSpacing = 48; // Space between list items

const Wheel = styled(motion.ul)`
  position: relative;
  height: ${verticalSpacing * 5}px;
  list-style: none;
  margin: 0;
  padding: 0;

  &:after {
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 40px;
    border: 3px solid ${COLORS.blue400};
    border-radius: 50px;
    margin: auto;
    position: absolute;
    top: -7px; /* adjust for webfont baseline offset */
    left: 3px;
    bottom: 0;
    right: 0;
  }
`;

const ListItem = styled(motion.li)`
  position: absolute;
  top: 100px;
  left: ${SPACING.one}px;
  ${typography.h2}
  font-size: 32px; /* we do not want responsive font-size for this one */
`;

const clamp = (val: number, min: number, max: number): number => {
  return Math.max(min, Math.min(val, max));
};

const WheelItem = ({
  index,
  activeIndex,
  label,
  verticalSpacing,
  animationTime,
}: {
  index: number;
  activeIndex: number;
  label: string;
  verticalSpacing: number;
  animationTime: number;
}): ReactElement => {
  const inputRange = [-3, -2, -1, 0, 1, 2, 3];
  const outputRanges = {
    y: [
      verticalSpacing * -3,
      verticalSpacing * -2,
      verticalSpacing * -1,
      0,
      verticalSpacing * 1,
      verticalSpacing * 2,
      verticalSpacing * 3,
    ],
    opacity: [0, 0.5, 1, 1, 1, 0.5, 0],
    color: [COLORS.grey, COLORS.grey, COLORS.grey, COLORS.blue400, COLORS.grey, COLORS.grey, COLORS.grey],
    scale: [0, 0.5, 1, 1, 1, 0.5, 0],
  };

  const convertYrange = transform(inputRange, outputRanges.y);
  const convertOpacityRange = transform(inputRange, outputRanges.opacity);
  const convertColorOutputRange = transform(inputRange, outputRanges.color);
  const convertScaleOutputRange = transform(inputRange, outputRanges.scale);

  const calculatedIndex = index - activeIndex;
  const clampedIndex = clamp(calculatedIndex, -3, 3);

  const y = convertYrange(clampedIndex);
  const opacity = convertOpacityRange(clampedIndex);
  const color = convertColorOutputRange(clampedIndex);
  const scale = convertScaleOutputRange(clampedIndex);

  return (
    <ListItem
      animate={{ y: y, opacity: opacity, color: color, scaleY: scale }}
      transition={{ duration: animationTime, ease: ANIMATION_CURVES.bezier }}
    >
      {label}
    </ListItem>
  );
};

export const MessageWheel = ({
  messages,
  animationInterval = 2,
  animationTime = 0.6,
  ...rest
}: MessageWheelProps): ReactElement => {
  const [tick, setTick] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tick >= messages.length - 3) {
        setTick(2);
        return;
      }
      setTick(tick + 1);
    }, animationInterval * 1000);
    return () => clearInterval(interval);
  }, [animationInterval, messages, tick]);

  return (
    <AnimatePresence>
      <Wheel initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2, delay: 1 }} {...rest}>
        {messages.map((m, i) => (
          <WheelItem
            animationTime={animationTime}
            key={`${i}${m}`}
            index={i}
            verticalSpacing={verticalSpacing}
            activeIndex={tick}
            label={m}
          />
        ))}
      </Wheel>
    </AnimatePresence>
  );
};
