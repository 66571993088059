import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { ANIMATION_CURVES } from '../../styles/theme-constants';
import { AccordionItemProps } from './accordion-props';
import { AccordionButton, AccordionContent, AccordionWrapper, CloseButton } from './accordion-styles';
import { createAccordionItemId, useAccordion, UseAccordion } from './useAccordion';

const variants = {
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      height: { duration: 0.2, ease: ANIMATION_CURVES.bezier },
      opacity: { duration: 0.3, ease: ANIMATION_CURVES.bezier },
    },
  },
  enter: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: {
        duration: 0.3,
        ease: ANIMATION_CURVES.bezier,
      },
      opacity: {
        duration: 0.4,
        ease: ANIMATION_CURVES.bezier,
      },
    },
  },
};

export interface AccordionProps extends UseAccordion {
  children: React.ReactNode;
  id: string;
}

export const Accordion = ({ children, defaultIndex, id, ...props }: AccordionProps): React.ReactElement => {
  const { getAccordionItemProps } = useAccordion({ defaultIndex });
  return (
    <div {...props}>
      {React.Children.map(children as any, (child: React.ReactNode, i) => {
        if (!React.isValidElement(child)) return;

        const itemProps = getAccordionItemProps(i);
        const { buttonId, panelId } = createAccordionItemId(id, i);

        return React.cloneElement(child, {
          ...child.props,
          ...itemProps,
          index: i,
          buttonId,
          panelId,
        });
      })}
    </div>
  );
};

export const AccordionItem = (props: AccordionItemProps): React.ReactElement => {
  const {
    title,
    textAlignment,
    textSize,
    lineColor,
    backgroundColor,
    isOpen,
    onChange,
    children,
    color,
    buttonId,
    panelId,
    plain,
    ...rest
  } = props;
  const itemRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    // safari has no 'smooth' scroll built in for adjust the accordion
    smoothscroll.polyfill();
  }, []);

  const onComplete = (): void => {
    if (!isOpen || !itemRef.current) return;
    const navOffset = 72;

    // needs a delay here to stop it scrolling before accordion is fully expanded
    setTimeout(() => {
      const scroll = window.pageYOffset;
      let top = 0;
      if (itemRef && itemRef.current) {
        const { top: rectop } = itemRef.current.getBoundingClientRect();
        top = rectop;
      }

      const currentScrollDepth = scroll + top - navOffset;
      window.scrollTo({ left: 0, top: currentScrollDepth, behavior: 'smooth' });
    }, 500);
  };

  const buttonAriaProps = {
    'aria-expanded': !!isOpen,
    id: buttonId,
    'aria-controls': panelId,
  };

  const panelAriaProps = {
    id: panelId,
    role: 'region',
    'aria-labelledby': buttonId,
  };

  return (
    <AccordionWrapper ref={itemRef} lineColor={lineColor} plain={plain} backgroundColor={backgroundColor} {...rest}>
      <h3>
        <AccordionButton
          textAlignment={'center'}
          textSize={textSize}
          color={color}
          {...buttonAriaProps}
          onClick={() => onChange(isOpen)}
        >
          {title}
          <CloseButton color={color} isOpen={isOpen}>
            <span />
          </CloseButton>
        </AccordionButton>
      </h3>
      <AccordionContent
        color={color}
        initial={false}
        {...panelAriaProps}
        variants={variants}
        animate={isOpen ? 'enter' : 'exit'}
        onAnimationComplete={onComplete}
        textAlignment={textAlignment}
      >
        {children}
      </AccordionContent>
    </AccordionWrapper>
  );
};
