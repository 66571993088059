import React, { ReactElement } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { responsiveMedia } from '../../helpers/media-queries';
import { responsiveProp } from '../../helpers/responsiveProp';
import { AspectRatio as AspectRatioEnum } from '../grid/box';

type Ratio = {
  [key in AspectRatioEnum]: number | undefined;
};

export const RATIO = {
  oneByOne: 1,
  twoByOne: 2 / 1,
  oneByTwo: 1 / 2,
  free: undefined,
} as Ratio;

// in ts4.1 we can `${AspectRatioEnum}` to link the enum
type RatioUnion = 'oneByOne' | 'twoByOne' | 'oneByTwo' | 'free';

export interface AspectRatioProps {
  ratio?: number | number[] | RatioUnion | RatioUnion[];
  children: React.ReactNode;
  showOverflow?: boolean;
  skipStylingChildren?: boolean;
  justifyContent?: string | string[];
  alignItems?: string | string[];
}

const getRatio = (ratio?: AspectRatioEnum | number | undefined): number | undefined => {
  if (ratio === undefined || ratio === null) return undefined;

  if (typeof ratio === 'number') {
    return ratio;
  }

  if (RATIO[ratio]) {
    return RATIO[ratio];
  }

  return undefined;
};

const getBeforeStyles = (ratio?: number | undefined): FlattenSimpleInterpolation => {
  // base reset styles
  if (ratio === undefined || ratio === null) {
    return css`
      content: unset;
      height: auto;
      display: initial;
      padding-top: unset;
      position: unset;
    `;
  }

  return css`
    height: 0;
    content: '';
    display: block;
    padding-bottom: ${(1 / ratio) * 100}%;
  `;
};

const getChildStyles = (
  ratio?: number | undefined,
  skipStylingChildren = false,
  i: number,
): FlattenSimpleInterpolation => (props: AspectRatioProps) => {
  if (ratio === undefined || ratio === null) {
    return css`
      position: static;
    `;
  }

  return css`
    overflow: ${props.showOverflow ? 'initial' : 'hidden'};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${skipStylingChildren ||
    css`
      display: flex;
      justify-content: ${props.justifyContent[i] || 'center'};
      align-items: ${props.alignItems[i] || 'center'};
      width: 100%;
      height: 100%;
    `}
  `;
};

const StyledAspectRatio = styled.div<Partial<AspectRatioProps>>`
  position: relative;

  &:before {
    ${({ ratio = [undefined] }) =>
      responsiveMedia.map((media, i) => {
        return media`
            ${getBeforeStyles(ratio[i])}
        `;
      })}
  }

  & > * {
    ${props => {
      const { ratio = [undefined], skipStylingChildren } = props;
      return responsiveMedia.map((media, i) => {
        return media`
          ${getChildStyles(ratio[i], skipStylingChildren, i)(props)}
      `;
      });
    }}
  }
`;

export const AspectRatio = (props: AspectRatioProps): ReactElement => {
  const {
    children,
    ratio,
    showOverflow = false,
    skipStylingChildren = false,
    alignItems,
    justifyContent,
    ...rest
  } = props;

  // enforce single child
  const child = React.Children.only(children);

  const responsiveRatio = responsiveProp({ prop: ratio, mapper: val => getRatio(val) });
  const responsiveJustify = responsiveProp({ prop: justifyContent });
  const responsiveAlign = responsiveProp({ prop: alignItems });

  return (
    <StyledAspectRatio
      ratio={responsiveRatio}
      showOverflow={showOverflow}
      skipStylingChildren={skipStylingChildren}
      justifyContent={responsiveJustify}
      alignItems={responsiveAlign}
      {...rest}
    >
      {child}
    </StyledAspectRatio>
  );
};
