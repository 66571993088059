import { Grid, Box } from '@cheese-fondue/components/grid';
import { Text } from '@cheese-fondue/components/atoms';
import { FluidObject } from 'gatsby-image';
import { COLORS, SIZES } from '@cheese-fondue/styles';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { TextLink, Wrapper, TextWrapper, RightSideWrapper, DiagonalBox, FixedPre } from './heroSecondaryStyles';
import { run } from 'play-core/index';
import { Container } from '@cheese-fondue/styles/container';
import { LinkAndSmallText, LinkAndSmallTextProps } from '../linkAndSmallText/linkAndSmallText';
import { Image } from '@cheese-fondue/components/atoms/';
import { SPACING } from '@cheese-fondue/styles';
import * as program from '@cheese-fondue/components/how-it-works-algo/dependencies/donut-black';
import { AspectRatio } from '../aspectRatio';

export interface HeroSecondaryProps {
  title?: string;
  linkText?: string;
  linkUrl?: string;
  subtitles?: string[];
  bgImage: FluidObject;
  bottomImage: FluidObject;
  linkAndTextData: LinkAndSmallTextProps[];
}

export const HowItWorksAlgo = (): ReactElement => {
  const preRef = useRef<HTMLPreElement>(null);
  const [scale, setScale] = useState(30);

  const forceRefresh = (e: MediaQueryListEvent | MediaQueryList): void => {
    return e.matches ? setScale(25) : setScale(30);
  };

  useEffect(() => {
    if (preRef && preRef.current) {
      const settings = {
        scale: scale,
        fps: 30,
        element: preRef.current,
      };

      run(program, settings).catch(() => {
        // TODO: replace with fallback image
      });
    }
  }, [preRef, scale]);

  useEffect(() => {
    let mediaQuery: MediaQueryList;
    if (window) {
      mediaQuery = window.matchMedia(`(min-width: ${SIZES.medium}px)`);
      mediaQuery.addListener(forceRefresh);
      forceRefresh(mediaQuery);
    }

    return () => {
      mediaQuery.removeListener(forceRefresh);
    };
  }, []);

  return (
    <section>
      <AspectRatio ratio="oneByOne">
        <DiagonalBox>{<FixedPre ref={preRef} />}</DiagonalBox>
      </AspectRatio>
    </section>
  );
};

export const HeroSecondary = (props: HeroSecondaryProps): ReactElement => {
  const { title, subtitles, linkText, linkUrl, bgImage, bottomImage, linkAndTextData } = props;

  return (
    <Wrapper>
      <RightSideWrapper bgImage={bgImage.src}>
        <HowItWorksAlgo />
      </RightSideWrapper>
      <Container>
        <Grid>
          <Box cols={[10, 8, 5]}>
            <TextWrapper>
              <Text
                as="h2"
                size={['h1', 'h1XLarge', 'h1XLarge']}
                color={COLORS.blue300}
                mb={'two'}
                mt={['seven', 'eight', 'twelve']}
              >
                {title}
              </Text>
              {subtitles &&
                subtitles.map((subtitle, index) => (
                  <Text
                    key={index}
                    as="p"
                    size={['p', 'pMedium', 'pMedium']}
                    color={COLORS.green300}
                    mb={'two'}
                    maxWidth={'580px'}
                  >
                    {subtitle}
                  </Text>
                ))}
              {linkText && linkUrl && <TextLink href={linkUrl}>{linkText}</TextLink>}
            </TextWrapper>
          </Box>
        </Grid>
        <Grid
          colspan={[12, 10, 12]}
          css={[
            `margin-bottom: ${SPACING.two}px`,
            `margin-top: 0px; padding-bottom: ${SPACING.five}px;`,
            `margin-top: ${SPACING.eight}px; padding-bottom: ${SPACING.five}px;`,
          ]}
        >
          {linkAndTextData.map(linkAndTextItem => {
            return (
              <>
                <Box cols={[1, 0]} key={linkAndTextItem.linkText}></Box>
                <Box
                  cols={[12, 3, 4]}
                  css={[
                    `margin-bottom: ${SPACING.two}px; margin-top: ${SPACING.one}px;`,
                    `margin-bottom: ${SPACING.three}px; margin-top: ${SPACING.one}px;`,
                    `margin-bottom: ${SPACING.two}px; margin-top: ${SPACING.two}px;`,
                  ]}
                >
                  <LinkAndSmallText
                    linkText={linkAndTextItem.linkText}
                    linkUrl={linkAndTextItem.linkUrl}
                    text={linkAndTextItem.text}
                    hasArrow
                  />
                </Box>
                <Box cols={[1, 0]}></Box>
              </>
            );
          })}
        </Grid>
      </Container>
      <Grid>
        <Box cols={[12, 0, 0]}>
          <Image imageData={bgImage} altText={''} />
        </Box>
        <Box cols={[0, 12, 0]}>
          <Image imageData={bottomImage} altText={''} />
        </Box>
      </Grid>
    </Wrapper>
  );
};
