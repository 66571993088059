import { media } from '@cheese-fondue/helpers';
import styled from 'styled-components';
import { SIZES, SPACING } from './theme-constants';

export const Container = styled.div`
  max-width: ${SIZES.large}px;
  margin: 0 auto;
  width: 100%;

  ${media.small`
    padding: 0px ${SPACING.two}px;
  `}

  ${media.medium`
    padding: 0px ${SPACING.four}px;
  `}

  ${media.large`
    padding: 0px;
  `}
`;
