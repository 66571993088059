import { media } from '@cheese-fondue/helpers';
import { COLORS, SIZES } from '@cheese-fondue/styles';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import styled from 'styled-components';

export const LogoTextLinkBlockWrapper = styled.div`
  ${speckledBackground(COLORS.white100)};
  padding: 40px 0;

  ${media.medium`
    padding: 96px 0;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  max-width: ${SIZES.large}px;
  margin: 0 auto;
  width: 100%;
  gap: 32px;
  padding: 0 16px;

  ${media.medium`
    flex-direction: row;
    justify-content: space-between;
    gap:16px;
  `}
`;
