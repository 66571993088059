import styled, { css } from 'styled-components';
import { media } from '../../helpers/media-queries';
import { COLORS, SPACING } from '../../styles';
import { FONTS } from '../../styles/theme-constants';
import { typography } from '../../styles/typography';
import { TimelineProps } from './timeline-props';

type PartialTimelineProps = Partial<TimelineProps>;
type ColorProps = {
  textColor?: string;
  dateColor?: string;
  lineColor?: string;
  circleDivider?: boolean;
  bottom?: boolean;
  circleEnds?: boolean;
  minWidth?: string;
};

export const TimelineListWrapper = styled.div<PartialTimelineProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: ${({ endIcon }) => (endIcon ? `${SPACING.two}px` : 0)};
`;

export const TimelineList = styled.ul<PartialTimelineProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  align-items: center;
  width: 100%;
  position: relative;

  ${({ circleEnds }) =>
    circleEnds &&
    css`
      &:before,
      &:after {
        width: 2px;
        background-color: ${({ lineColor = COLORS.blue100 }) => lineColor};
        content: '';
        height: ${SPACING.three}px;
      }
    `}

  ${({ endIcon, lineColor = COLORS.blue100 }) =>
    endIcon &&
    css`
      &:after {
        width: 2px;
        background-color: ${lineColor};
        content: '';
        height: ${SPACING.four}px;
      }
    `}


  ${media.medium`
    &:before,
    &:after {
        width: 2px;
        background-color: ${({ lineColor = COLORS.blue100 }) => lineColor};
        content: '';
        height: ${SPACING.six}px;
    }

    ${({ circleEnds }) =>
      circleEnds &&
      css`
        &:after {
          height: ${SPACING.two}px;
        }
      `}
  `}
`;

export const TimelineContent = styled.div<ColorProps>`
  flex: 1;
  padding: 0;
  min-width: ${({ minWidth = `60%` }) => minWidth};
  color: ${({ textColor = COLORS.orange100 }) => textColor};

  ${media.medium`
     padding: ${SPACING.one}px 0;
  `}

  p:only-child {
    margin-top: 0;
  }
`;

export const TimelineContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.medium`
    flex-direction:row;
  `}
`;

export const TimelineItemWrapper = styled.li<ColorProps>`
  display: flex;
  position: relative;
  list-style: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 ${SPACING.two}px;

  &:before,
  &:after {
    width: 2px;
    background-color: ${({ lineColor = COLORS.blue100 }) => lineColor};
    content: '';
    height: ${SPACING.two}px;
  }

  ${media.medium`

        align-items:flex-start;
        &:before {
            content: '';
            flex: 1;
            background: none;
            width:0;
            height:0;
        }

        &:after {
            content: unset;
            background: unset;
            height:unset;
            width:unset;
        }
  `}

  ${media.medium`
    flex-direction:row;

      &:nth-child(even) {
        flex-direction: row-reverse;

        ${TimelineContentWrapper} {
          flex-direction: row-reverse;
        }
      }
  `}
`;

export const EndCircle = styled.span<ColorProps>`
  height: 15px;
  width: 15px;
  position: absolute;
  border-radius: 50%;
  background-color: ${props => (props.lineColor ? props.lineColor : `${COLORS.blue100}`)};
  bottom: ${({ bottom }) => bottom && '0'};
`;

export const TimelineSeparator = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const Divider = styled.span<ColorProps>`
  ${typography.h3};
  font-family: ${FONTS.labels};
  color: ${({ dateColor = COLORS.orange400 }) => dateColor};
  text-align: center;

  margin: ${props => (props.circleDivider ? '16px 0 0' : '')};
  padding: ${props => (props.circleDivider ? '0' : `${SPACING.one / 2}px ${SPACING.one}px 0`)};
  background-color: ${props => (props.circleDivider ? COLORS.orange400 : '')};
  height: ${props => (props.circleDivider ? '20px' : '')};
  width: ${props => (props.circleDivider ? '20px' : '')};
  border-radius: ${props => (props.circleDivider ? '50%' : '')};

  ${media.medium`
    margin: ${props => (props.circleDivider ? '16px 0' : '')};
    padding: ${props => (props.circleDivider ? '0' : `${SPACING.one}px 0`)};
  `}
`;

export const TimelineSeparatorLine = styled.div<ColorProps>`
  flex-grow: 1;
  width: 2px;
  background-color: ${({ lineColor = COLORS.blue100 }) => lineColor};
  min-height: ${SPACING.four}px;
  display: none;

  ${media.medium`
    min-height: ${SPACING.five}px;
    display:block;
  `}
`;

export const TimelineConnector = styled.div<ColorProps>`
  padding: ${SPACING.one}px 0 0;
  flex: 1;

  ${media.medium`
    padding: ${SPACING.one}px ${SPACING.one}px 0;
  `}

  hr {
    display: none;
    border-top: 4px dotted ${({ lineColor = COLORS.blue100 }) => lineColor};
    border-bottom: none;
    border-right: none;
    border-left: none;
    width: 100%;

    ${media.medium`
      display:block;
    `}
  }
`;
