import { media } from '@cheese-fondue/helpers';
import { COLORS, FONTS, SPACING } from '@cheese-fondue/styles';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import styled from 'styled-components';

export const Wrapper = styled.section`
  ${speckledBackground(COLORS.orange300)};

  ${media.medium`
    min-height: 62vh;
    position: relative;
  `}
`;

export const RightSideWrapper = styled.div<{ bgImage: string }>`
  display: none;

  ${media.large`
    display: block;
    background-image: url(${({ bgImage }) => bgImage});
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 52%;
  `}
`;

export const TextLink = styled.a<StyleProps>`
  color: ${COLORS.blue300};
  font-family: ${FONTS.primary};
  font-size: 20px;
`;

export const TextWrapper = styled.div`
  margin-bottom: ${SPACING.four}px;
`;

export const DiagonalBox = styled.div`
  width: 100%;
  height: 100%;
`;

export const FixedPre = styled.pre`
  color: ${COLORS.orange100};
  font-family: monospace;
  font-size: 10px;
  height: 100%;
  margin: 30px 0 0 30px;
  width: 100%;
  position: absolute;
  top: 2%;
  right: -8%;
  ${media.medium`
    font-size: 14px;
    height: 600px;
    margin: 60px 0 0 60px;
    width: 600px;
  `}
`;
